/* breadcrumb */
nav.breadcrumb ol.breadcrumb .breadcrumb-item:not(.active) {
  color: inherit !important;
}
nav.breadcrumb ol.breadcrumb .breadcrumb-item:not(.active) a {
  color: inherit !important;
}

/* button */
button,
button.btn {
  background-color: #065DFF;
}
button:hover,
button.btn:hover {
  background-color: #065DFF;
}
button.btn-link,
button.btn.btn-link {
  color: #065DFF;
}

.new-btn {
  background-color: #065DFF !important;
}

.new-btn:hover {
  background-color: #065DFF;
}

.search-btn:hover {
  color: inherit !important;
}

/* content */
.content-middle .nav-tabs .nav-item .nav-link:hover {
  color: #065DFF;
  border-bottom-color: #065DFF;
}
.content-middle .nav-tabs .nav-item .nav-link.active {
  color: #065DFF;
  border-bottom-color: #065DFF;
}

/* form */
form div.form-group input[type=text]:focus,
form div.form-group input[type=text]:-webkit-autofill:focus,
form div.form-group input[type=password]:focus,
form div.form-group input[type=password]:-webkit-autofill:focus {
  border-bottom-color: #065DFF;
}
form div.form-group select:focus {
  border-bottom-color: #065DFF;
}
form > div:not(.form-group) a {
  color: inherit !important;
}

/* list-actions */
.list-actions.no-border .link-action-register {
  color: #065DFF;
}
.list-actions:not(.list-actions-active):hover {
  background-color: #065DFF;
  border-color: #065DFF;
}
.list-actions > div .title-group-list > strong {
  color: #065DFF;
}
.list-actions button {
  border-color: #065DFF;
  color: #065DFF;
}
.list-actions button:hover {
  background-color: #065DFF;
  border-color: #065DFF;
}

/* menu */
.menu.small .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a:hover, .menu.small .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a.selected {
  color: #065DFF;
  border-left-color: #065DFF;
}
.menu.small .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a:hover i, .menu.small .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a.selected i {
  color: #065DFF;
}
.menu.small .area-menu footer p a .contractor-primary-color {
  color: #065DFF;
}
.menu:not(.small) .area-menu #menus .menu-system #welcome-user span {
  color: #065DFF;
}
.menu:not(.small) .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a:hover, .menu:not(.small) .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a.selected {
  color: #065DFF;
  border-left-color: #065DFF;
}
.menu:not(.small) .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a:hover i, .menu:not(.small) .area-menu #menus .menu-system .content-menu .nav-principal .nav-item a.selected i {
  color: #065DFF;
}
.menu:not(.small) .area-menu footer p a {
  color: #065DFF;
}
.btns-login .text-center label a {
  color: #065DFF !important;
}

.btns-login button[type=submit] {
  background-color: #065DFF;
}

/* dashboard */
.dashboardGrid .widget.medium-widget svg {
  background: #065DFF;
}
.dashboardGrid .pie {
  border-color: #065DFF;
}
.dashboardGrid .c100 > span {
  color: #065DFF;
}

/* login */
.login-card form button.login-btn {
  background-color: #065DFF ;
}

/* react-table */
.ReactTable .pagination-bottom .Table__prevPageWrapper .Table__pageButton,
.ReactTable .pagination-bottom .Table__nextPageWrapper .Table__pageButton {
  border-color: #065DFF;
  color: #065DFF;
}
.ReactTable .pagination-bottom .Table__prevPageWrapper .Table__pageButton:hover,
.ReactTable .pagination-bottom .Table__nextPageWrapper .Table__pageButton:hover {
  border-color: #065DFF;
  background-color: #065DFF;
}
.ReactTable .pagination-bottom .Table__visiblePagesWrapper {
  border-color: #065DFF;
}
.ReactTable .pagination-bottom .Table__pageButton {
  color: #bbb;
}
.ReactTable .pagination-bottom .Table__pageButton:hover {
  border-color: #065DFF;
  color: #065DFF;
}
.ReactTable .pagination-bottom .Table__pageButton--active {
  border-color: #065DFF;
  background-color: #065DFF;
  color: #065DFF;
}

/* maps */
.map-nota .image-map .btns-map .btns-top-map button {
  border-color: #065DFF;
  color: #065DFF;
}
.map-nota .image-map .btns-map .btns-top-map button:hover {
  border-color: #065DFF;
  color: #065DFF;
}
.map-nota .image-map .btns-map .btns-top-map button.active {
  background-color: #065DFF;
  border-color: #065DFF;
}
.map-nota .fields-maps .cards-fields .card .collapse .card-body .row .form-group input:focus,
.map-nota .fields-maps .cards-fields .card .collapsing .card-body .row .form-group input:focus {
  border-color: inherit !important;
}

/* roles */

.roles-form form div .tab-content .tab-pane fieldset {
  border-color: inherit !important;
}
.roles-form form div .tab-content .tab-pane fieldset .form-control .form-check.form-check-inline input[type=radio]:checked + label:before {
  background: inherit !important;
  border-color: inherit !important;
}
.roles-form form div .tab-content .tab-pane fieldset .form-control .form-check.form-check-inline input[type=checkbox]:checked + label:before {
  background: inherit !important;
  border-color: inherit !important;
}

/*# sourceMappingURL=configUser.css.map */
