.lowerV2 {
    transform: translate(-7.5px, 117.4px);
    animation-name: detachLogo;
    animation-duration: 6s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}

.loadingV2 {
    margin: 20px;
    width: 150px;
    height: 150px;
    animation-name: spinLogo;
    animation-duration: 6s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}

.loadingtextV2 {
    animation-name: fadeText;
    animation-duration: 6s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}

.loadingtextV2::after {
    content: 'Carregando...';
    animation-name: typing;
    animation-duration: 6s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
}

@keyframes spinLogo {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(-15deg);
    }
    15% {
        transform: rotate(-15deg);
    }
    25% {
        transform: rotate(15deg);
    }
    30% {
        transform: rotate(15deg);
    }
    40% {
        transform: rotate(0);
    }
    45% {
        transform: rotate(0);
    }
    55% {
        transform: rotate(-15deg);
    }
    60% {
        transform: rotate(-15deg);
    }
    70% {
        transform: rotate(15deg);
    }
    75% {
        transform: rotate(15deg);
    }
    85% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

@keyframes detachLogo {
    0% {
        transform: rotate(0) translate(-7.5px, 117.4px);
        fill: #37d5bb;
    }

    80% {
        transform: rotate(0) translate(-7.5px, 117.4px);
        fill: #37d5bb;
    }

    90% {
        transform-origin: -6px 236px;
        transform: rotate(86.8deg) translate(-7.5px, 117.4px);
        fill: #009387;
    }

    98% {
        transform-origin: -6px 236px;
        transform: rotate(86.8deg) translate(-7.5px, 117.4px);
        fill: #009387;
    }

    100% {
        transform-origin: -6px 236px;
        transform: rotate(0) translate(-7.5px, 117.4px);
        fill: #37d5bb;
    }
}

@keyframes fadeText {
    0% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    83% {
        opacity: 0;
    }

    98% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes typing {
    0% {
        content: 'Carregando...';
    }
    10% {
        content: 'Carregando..‎‎‎‏‏‎ ';
    }
    15% {
        content: 'Carregando..‏‏‎ ‎‎‎';
    }
    25% {
        content: 'Carregando.‎‎‎‎‏‏‎  ';
    }
    30% {
        content: 'Carregando.‎‎‎‎‏‏‎  ';
    }
    40% {
        content: 'Carregando..‎‎‏‏‎ ';
    }
    45% {
        content: 'Carregando.. ‎';
    }
    55% {
        content: 'Carregando...';
    }
    60% {
        content: 'Carregando...';
    }
    70% {
        content: 'Carregando..‏‏‎ ‎';
    }
    75% {
        content: 'Carregando.. ‎';
    }
    85% {
        content: 'Carregando...';
    }
    100% {
        content: 'Carregando...';
    }
}
